import React, { useRef, useState } from "react";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import AXIOS from '../../../axios-config';
import cogoToast from 'cogo-toast';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "gatsby";

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import ImageBackground from '../../components/imagebackground';
import MotorSchoolCard from '../../components/motorrad/motorschoolcard';
import Spinner from '../../components/spinner';
import settings from '../../../settings';

const topbanner = `${settings.IMAGES_BASE_URL}/images/motorrad/private-training/private_lesson_header.jpg`;
const motortour = `${settings.IMAGES_BASE_URL}/images/motorrad/private-training/bmw-private-lessons.jpg`;

const PrivateTraining = () =>  {
  const [isLoading,setIsLoading] = useState(false)
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  const initialValues = {
    firstname: '',
    lastname: '',
    organization: '',
    zipcode: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    event_date_desired: '',
    selectedTrainingType: [],
    notes: ''
  };
  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    organization: Yup.string()
      .required('Please enter your organization')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    address: Yup.string()
      .required('Please enter your address')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    city: Yup.string()
      .required('Please enter your city')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    state: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    zipcode: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    event_date_desired: Yup.date().required('Please choose your event date'),
		email: Yup.string()
			.email()
			.required("Please enter your email address")
			.matches(
				/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
				"Your email is incorrect"
			),
      phone: Yup.string()
			.required("Please enter your phone number")
			.matches(phoneRegExp, "Please enter a valid phone number"),
    selectedTrainingType:
    Yup.array().required(
			"Please select at least one of the tours"
		)
  });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const headers = {
      'captchaToken': captchaToken
    };

    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }
    setIsLoading(true)
    setSubmitting(true);
    AXIOS.post('/api/privatetraining', values, {headers})
      .then(() => {
        errorHandling(setSubmitting);
        resetForm();
        cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
      });
  };

  const contentRef = useRef(null)

  const scrollToContent= ()=>{
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  }

    return (
      <Layout>
        <SEO
          title="The BMW Rider Academy School | Touring"
          description="BMW motorrad training includes the basics, street survival, advanced on-road training, a USRA Authority course and even women's on and off-road classes!"
        />
        <div className="touring">
          <ImageBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Touring Private Top Banner"
            scrollTo={scrollToContent}
          />
          <div className="container" ref={contentRef}>
            <div className="mototours__header">
              <h1>Private Training</h1>
              <p>
              To become the best, you’ve got to learn from the best. Our private training sessions pair
              you with one of our riding instructors for an intense day that focuses on the areas you want.
              Fill out the form below to find out more information.
              </p>
            </div>
          </div>
          <div className="motortours">
            <MotorSchoolCard
              rowType="row"
              image={motortour}
              imageAlt="TWO-DAY SMOKY MOUNTAIN TWISTED TOURS"
              header="CUSTOM MOTO TOURS"
            >
              <h6>Private Training</h6>
              <p>$1,400 per day</p>
              <div className="description">
                <p>
                A 1:1 full day with a BMW U.S. Rider Academy Instructor. Customize your day any way you like, with full use of
                the facility available. Mix on- and off-road training, choose specific exercises or tell us what you’d like to
                improve upon, and we’ll design a day around your needs. We offer flexible scheduling to accommodate work, flight
                and hotel demands. Use of a BMW GS bike and lunch is included.
                </p>
              </div>
            </MotorSchoolCard>
          </div>
          <section className="getstarted">
            <div className="container">
              <div className="getstarted__header">
                <h2>READY TO GET STARTED?</h2>
                <p>
                  If you’re interested in a private training session, please fill out the form below and a Motorrad Instructor will contact you directly.
                </p>
                <p>
                  If you have a specific date in mind, please let us know, and we will do our best to accommodate you.
                </p>
              </div>
              <div className="getstarted__content">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="checkbox">
                        <h4>Select Training Type</h4>
                        <div className="checkbox__group">
                          <Field aria-label="on_road_session" type="checkbox" name="selectedTrainingType" value="On-road Training session" />
                          <label htmlFor="twisted">On-road Training session </label>
                        </div>
                        <div className="checkbox__group">
                          <Field aria-label="off_road_session" type="checkbox" name="selectedTrainingType" value="Off-road Training session" />
                          <label htmlFor="adventure">Off-road Training session</label>
                        </div>
                        {errors.selectedTrainingType && touched.selectedTrainingType ? (
                          <span className="input-feedback">{errors.selectedTrainingType}</span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                      <div className="input">
                        <h4>Information</h4>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="firstname">First Name</label>
                            <Field
                              aria-label="firstname"
                              type="text"
                              name="firstname"
                              className={touched.firstname && errors.firstname ? 'has-error' : null}
                            />
                            {errors.firstname && touched.firstname ? (
                              <span className="input-feedback">{errors.firstname}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="lastname">Last Name</label>
                            <Field
                              aria-label="lastname"
                              type="text"
                              name="lastname"
                              className={touched.lastname && errors.lastname ? 'has-error' : null}
                            />
                            {errors.lastname && touched.lastname ? (
                              <span className="input-feedback">{errors.lastname}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="organization">Organization</label>
                            <Field
                              aria-label="organization"
                              type="text"
                              name="organization"
                              className={touched.organization && errors.organization ? 'has-error' : null}
                            />
                            {errors.organization && touched.organization ? (
                              <span className="input-feedback">{errors.organization}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="email">Email</label>
                            <Field
                              aria-label="email"
                              type="email"
                              name="email"
                              className={touched.email && errors.email ? 'has-error' : null}
                            />
                            {errors.email && touched.email ? (
                              <span className="input-feedback">{errors.email}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="input__group">
                          <label htmlFor="address">Address</label>
                          <Field
                            aria-label="address"
                            type="text"
                            name="address"
                            className={touched.address && errors.address ? 'has-error' : null}
                          />
                          {errors.address && touched.address ? (
                            <span className="input-feedback">{errors.address}</span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="city">City</label>
                            <Field
                              aria-label="city"
                              type="text"
                              name="city"
                              className={touched.city && errors.city ? 'has-error' : null}
                            />
                            {errors.city && touched.city ? <span className="input-feedback">{errors.city}</span> : (
                             <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group input--sm">
                            <label htmlFor="state">State</label>
                            <Field
                              aria-label="state"
                              type="text"
                              name="state"
                              className={touched.state && errors.state ? 'has-error' : null}
                            />
                            {errors.state && touched.state ? (
                              <span className="input-feedback">{errors.state}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="zipcode">Zip Code</label>
                            <Field
                              aria-label="zipcode"
                              type="text"
                              name="zipcode"
                              className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                            />
                            {errors.zipcode && touched.zipcode ? (
                              <span className="input-feedback">{errors.zipcode}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                          <div className="input__group">
                            <label htmlFor="phone">Phone</label>
                            <Field
                              aria-label="phone"
                              type="tel"
                              name="phone"
                              className={touched.phone && errors.phone ? 'has-error' : null}
                            />
                            {errors.phone && touched.phone ? (
                              <span className="input-feedback">{errors.phone}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="eventDate">Event Date desired</label>
                            <Field aria-label="event_date" type="date" name="event_date_desired" />

                            {errors.event_date_desired && touched.event_date_desired ? (
                              <span className="input-feedback">{errors.event_date_desired}</span>
                            ) : (
                              <span className="input-feedback">&nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <div className="input__group">
                            <label htmlFor="notes">Additional Notes</label>
                            <Field aria-label="notes" as="textarea" name="notes" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="legal_note">We respect your privacy. When you submit your contact information, we agree to only contact you in accordance with our <Link to="https://my.bmwmotorcycles.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.</p>
                      </div>
                      <br/>
                      <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
                      <button className="btn btn-bordered" type="submit" disabled={isSubmitting}>
                        {isLoading ? <Spinner /> : 'Submit'}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
}

export default PrivateTraining;
